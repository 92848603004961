import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProgressBar } from "../components/UI/ProgressBar";
import { gtag } from "ga-gtag";

const FacesContext = createContext([]);

export const useGetFacesData = () => {
  return useContext(FacesContext);
};

export function GetFacesProvider({ children }) {
  const [showNav, setShowNav] = useState(false);
  const [allFaces, setAllFaces] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let interval = null;
    if (loading) {
      interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 1
        );
      }, 30);
    } else if (!loading && progress !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [loading, progress]);

  const getFaces = async (e) => {
    const inputFile = e.target.files[0];
    // IF NO FILE IS SELECTED
    if (inputFile === undefined) return;
    // Google Analytics tracking event
    gtag("event", "SEARCH_STARTED", {
      "input": "onChange",
    });
    // Clearing result page before making new search
    setAllFaces({});
    setProgress(0);
    if (error) {
      setError("");
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", inputFile);
      const url =
        "https://a4o0jvc4j6.execute-api.us-east-1.amazonaws.com/facefinder";

      const response = await axios.post(url, formData);
      if (response.status === 200) {
        const faces = response.data;
        setProgress(100);
        setAllFaces(faces);
        setLoading(false);
        navigate("searchResults");
        // Google analytics track event
        if(faces.error && faces?.error !== 'NO_FACES_FOUND_IN_INPUT_IMAGE'){
          gtag("event", "NO_SIMILAR_FACES_WERE_FOUND", {
            "input": "onChange",
          });
        }
        if(faces?.error === 'NO_FACE_FOUND_IN_INPUT_IMAGE'){
          gtag("event", "NO_FACES_FOUND_IN_INPUT_IMAGE", {
            "input": "onChange",
          });
        }
      } else {
        setLoading(false);
        navigate("searchResults");
        setError("Error encountered. Please try again later");
        // Google Analytics tracking event
        gtag("event", "SERVER_ERROR", {
          "input": "onChange",
        });
      }
    } catch (err) {
      setLoading(false);
      navigate("searchResults");
      setError("Error encountered. Please try again later");
      // Google Analytics tracking event
      gtag("event", "SERVER_ERROR", {
        "input": "onChange",
      });
    }
  };

  return (
    <FacesContext.Provider
      value={{
        loading,
        error,
        allFaces,
        showNav,
        setShowNav,
        getFaces,
      }}
    >
      {loading && <ProgressBar progress={progress} />}
      {children}
    </FacesContext.Provider>
  );
}
